import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/layout"
// import { Component } from "react";
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"

import demoImg1 from "../images/0-demo-slider-1.png"
import demoImg2 from "../images/0-demo-slider-2.png"
import demoImg3 from "../images/0-demo-slider-3.png"

// images

const Sample = () => {
  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })
  const flickityOptions = {
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <Layout>
      <div className="mt-60">
        <div className="container mx-auto">
          {/* <div id="myid" style={{ paddingLeft: margin / 2 }}> */}
          {/* <div id="myid" style={{ paddingLeft: margin / 2 }}> */}
          <div
            id="myid"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            test
            <Flickity
              className={"case-studies-carousel cstm-du-casestudy-slider"}
              elementType={"div"}
              options={flickityOptions}
              ref={flRef}
            >
              <img className="cs-carousel-image" src={demoImg1} />
              <img className="cs-carousel-image" src={demoImg2} />
              <img className="cs-carousel-image" src={demoImg3} />
              <img className="cs-carousel-image" src={demoImg2} />
              <img className="cs-carousel-image" src={demoImg1} />
              <img className="cs-carousel-image" src={demoImg1} />
              <img className="cs-carousel-image" src={demoImg2} />
              <img className="cs-carousel-image" src={demoImg3} />
              <img className="cs-carousel-image" src={demoImg2} />
              <img className="cs-carousel-image" src={demoImg1} />
              <img className="cs-carousel-image" src={demoImg2} />
            </Flickity>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Sample
